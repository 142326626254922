<template>
  <!-- Start MCT breadcrumb section -->
  <BreadCrumb ImageUrl="/assets/images/shapes/header-bg-1.png" PageName="事業内容"/>
  <!-- End MCT breadcrumb section -->

 <section class="auninfo_contact cantact_v2 section_padding margin-top-about">
    <div class="container">
        <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
            <p><span>Business</span></p>
        </div>
        <div class="row">
             <!-- /.col-lg-6 -->
            <div class="col-lg-6 d-flex flex_phone">
                <div class="my-auto">
                    <div class="about-one__image wow slideInDown" ddata-aos="fade-right" :data-aos-duration="500" style="top:0">
                        <img src="/assets/images/choose-1.png" alt="" style="width: 100%;margin: 0 auto;margin-top: 50px;">
                    </div>
                    <!-- /.about-one__image -->
                </div>
                <!-- /.my-auto -->
            </div>
            <!-- /.col-lg-6 -->
            <div class="col-lg-6">
                <div class="about-one__content">
                    <div class="about-one__box-wrapper">
                        <div class="about-one__box">
                            <div class="about-one__box-icon">
                                <i class="fa fa-check"></i>
                            </div>
                            <!-- /.about-one__box-icon -->
                            <div class="about-one__box-content" data-aos="fade-right" :data-aos-duration="500">
                                <h3>SES</h3>
                                <p>当社のSES(システムエンジニアリングサービス)事業は、貴社の急な人手不足、イレギュラー対応が必要な短期的な作業にも、迅速にエンジニア/プログラマ等をご紹介致します。<br/>
                                    豊富な経験と高度なスキルを持つビジネスエンジニアが、お客様のプロジェクトを成功へと導くことをお手伝い致します。 <br/>
                                また、SES事業におけるパートナー企業様も積極的に募集しておりますので、ご興味のある企業様は、お気軽にご連絡下さいませ。</p>
                            </div>
                            <!-- /.about-one__box-content -->
                        </div>
                        <!-- /.about-one__box -->
                        <div class="about-one__box">
                            <div class="about-one__box-icon">
                                <i class="fa fa-check"></i>
                            </div>
                            <!-- /.about-one__box-icon -->
                            <div class="about-one__box-content" data-aos="fade-right" :data-aos-duration="500">
                                <h3>システム受託開発サービス</h3>
                                <p>MCTは、トータルソリューションのご提案から個別システム開発まで、幅広いニーズ・シーズにご対応が可能な少数精鋭のエンジニアチームが開発案件をお待ちしております。<br/>
                                    業務システムやWebサービス等の各種ソフトウェア開発について、要件定義、設計、プログラミング、テスト、運用の各工程に合わせたご要望を踏まえてハイスキルエンジニアが対応します。</p>
                            </div>
                            <!-- /.about-one__box-content -->
                        </div>
                    </div>
                    <!-- /.about-one__box-wrapper -->
                </div>
                <!-- /.about-one__content -->
            </div>
            <div class="col-lg-6 d-flex flex_pc">
                <div class="my-auto">
                    <div class="about-one__image wow slideInDown" ddata-aos="fade-right" :data-aos-duration="500" style="top:0">
                        <img src="/assets/images/choose-1.png" alt="" style="width: 100%;margin: 0 auto;margin-top: 50px;">
                    </div>
                    <!-- /.about-one__image -->
                </div>
                <!-- /.my-auto -->
            </div>
        </div>
        <div class="row row-line">
            <!-- /.col-lg-6 -->
            <div class="col-lg-6 d-flex">
                <div class="my-auto">
                    <div class="about-one__image wow slideInDown" data-aos="fade-right" :data-aos-duration="500" style="top:0">
                        <img src="/assets/images/choose-2.png" alt="">
                    </div>
                    <!-- /.about-one__image -->
                </div>
                <!-- /.my-auto -->
            </div>
            <!-- /.col-lg-6 -->
            <div class="col-lg-6">
                <div class="about-one__content">
                    <div class="about-one__box-wrapper">
                        <div class="about-one__box">
                            <div class="about-one__box-icon">
                                <i class="fa fa-check"></i>
                            </div>
                            <!-- /.about-one__box-icon -->
                            <div class="about-one__box-content" data-aos="fade-right" :data-aos-duration="500">
                                <h3>システム運用/保守サービス</h3>
                                <p>
                                    サーバー/ネットワークや汎用機の運用保守、開発保守など、幅広い技術スキルを擁しています。
                                    運用技術 <br>
                                    サーバーおよびネットワーク運用保守<br>
                                    グループウェアおよびデータベース運用保守<br>
                                    システム開発保守<br>
                                    クライアントサポート。
                                </p>
                            </div>
                            <!-- /.about-one__box-content -->
                        </div>
                        <!-- /.about-one__box -->
                        <div class="about-one__box">
                            <div class="about-one__box-icon">
                                <i class="fa fa-check"></i>
                            </div>
                            <!-- /.about-one__box-icon -->
                            <div class="about-one__box-content" data-aos="fade-right" :data-aos-duration="500">
                                <h3>BPO</h3>
                                <p>MCTは、トータルソリューションのご提案から個別システム開発まで、幅広いニーズ・シーズにご対応が可能な少数精鋭のエンジニアチームが開発案件をお待ちしております。<br/>
                                    業務システムやWebサービス等の各種ソフトウェア開発について、要件定義、設計、プログラミング、テスト、運用の各工程に合わせたご要望を踏まえてハイスキルエンジニアが対応します。</p>
                            </div>
                            <!-- /.about-one__box-content -->
                        </div>
                    </div>
                    <!-- /.about-one__box-wrapper -->
                </div>
                <!-- /.about-one__content -->
            </div>
        </div>
        <br/>
        <br/>
    </div>
 </section>
</template>

<script>
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "Business",
  components:{ BreadCrumb },
  setup() {}
}
</script>

<style scoped>

</style>
