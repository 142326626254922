<template>
  <!-- Start MCT breadcrumb section -->
  <BreadCrumb ImageUrl="/assets/images/shapes/header-bg-2.png" PageName="お問い合わせ"/>
  <!-- End MCT breadcrumb section -->

  <!-- End reinfo_breadcrumb section -->
  <!-- Start reinfo_contact section -->
  <section class="auninfo_contact cantact_v2 section_padding" style="margin-top:50px">
    <div class="container">
       <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
            <p><span>Contact</span></p>
        </div>
        <component :is="currentTab" class="cantact_box_margin_top"></component>
      </div>
  </section>
  <!-- End reinfo_contact section -->
</template>

<script>
import {onMounted, ref} from "vue";
import MailFrom from "../components/MailFrom";
import BreadCrumb from "../components/BreadCrumb";

export default {
  name: "Contact",
  components:{ MailFrom,BreadCrumb},
  setup(){
    const currentTab = ref('MailFrom')
    onMounted(() => {
      document.title = 'お問い合わせ - MCT';
    })
    return {currentTab}
  },
}
</script>
