<template>
<section class="banner-one" id="bannercroll">
    <div class="particles-snow" id="banner-one-snow"></div>
    <!-- <img src="/assets/images/shapes/page-header-shape-1-2.png" class="page-header__bg-shape-2" alt=""> -->
    <img src="/assets/images/shapes/footer-shape-1-1.png" class="page-header__bg-shape-3" alt="">
    <img src="/assets/images/shapes/footer-shape-1-3.png" class="page-header__bg-shape-4" alt="">
    <div class="t_first p_absoulte mobile_hide"><img class="layer layer2" style="opacity:0.2"  data-depth="-0.20" src="/assets/images/tr_one.png" alt=""></div>
    <div class="t_two p_absoulte mobile_hide"><img class="layer layer2" data-depth="0.30" src="/assets/images/tr_three.png" alt=""></div>
    <div class="t_three p_absoulte mobile_hide"><img class="layer layer2" data-depth="0.30" src="/assets/images/tr_two.png" alt=""></div>
    <img class="t_two p_absoulte mobile_hide" src="/assets/images/triangle_shap_two.png" alt="">
    <img class="t_shap p_absoulte mobile_hide" src="/assets/images/shap.png" alt="">
    <img class="b_shap p_absoulte mobile_hide" src="/assets/images/shap_two.png" alt="">
    <div class="s_round r_one p_absoulte mobile_hide"></div>
    <div class="s_round r_two p_absoulte mobile_hide"></div>
    <div class="s_round r_three p_absoulte mobile_hide"></div>
    <div class="s_round r_four p_absoulte"></div>
    <div class="s_round r_five p_absoulte"></div>
    <div class="s_round r_six p_absoulte"></div>
    <div class="s_round r_seven p_absoulte"></div>
    <div class="s_round r_eight p_absoulte"></div>
    <div class="s_round r_nine p_absoulte"></div>
    <div class="s_round r_ten p_absoulte"></div>
    <div class="s_round r_eleven p_absoulte"></div>
    <img class="memphis_bottom p_absoulte" src="/assets/images/line.png" alt="">
    <img class="dot_one p_absoulte" src="/assets/images/dot.png" alt="">
    <div class="section__bubble-1"></div><div class="section__bubble-2"></div><div class="section__bubble-3"></div><div class="section__bubble-4"></div><div class="section__bubble-5"></div><div class="section__bubble-6"></div><div class="section__bubble-7"></div><div class="section__bubble-8"></div>
    <!-- /#cta-one-snow.particles-snow -->
     <video class="background" autoplay muted loop poster="/assets/images/hero/bannerAsset1.png">
        <source src="/assets/banner.mp4" type="video/mp4"/>
     </video>
    　<div class="hero_slider container">
        <div class="signle_slider" id="signle_slider_1">
            <div class="banner-one__image">
                <img src="/assets/images/bannerAsset1.png" class="banner-one__image-1" alt="">
            </div>
            <div class="row" style="height:580px;">
                <div class="col-lg-6" style="padding-top:102px">
                    <div class="banner-two__content" data-aos="fade-right">
                        <h3><span>信用・信頼はビジネス成り立ちの基礎</span></h3>
                        <p>
                            「有言実行」、お客様からの御満足と御信頼を得る事を最も重視し、頂いた仕事は必ずやり遂げ、常に高品質で低コストのサービスを提供する事により、お客様との厚い信頼関係を構築して参ります。 
                        </p>
                        <div class="ot-button align-self-center">
                            <a href="/service" class="octf-btn octf-btn-icon octf-btn-secondary home_img_right">&emsp;サービス&emsp;<i class="flaticon-right-arrow-1"></i>
                            </a>
                            <a href="/philosophy" class="octf-btn octf-btn-primary octf-btn-icon">
                                &emsp;経営理念&emsp;<i class="flaticon-right-arrow-1"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="signle_slider" id="signle_slider_2">
            <div class="banner-one__image">
                <img src="/assets/images/bannerAsset2.png" class="banner-one__image-1 " alt="">
            </div>
            <div class="row" style="height:580px;">
                <div class="col-lg-6" style="padding-top:102px">
                    <div class="banner-two__content" data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">
                        <h3><span>お客様の価値創造に貢献して参ります</span></h3>
                        <p>常に新しい技術に挑戦しマスターする事で、
                        <br>新規事業を開拓しお客様の期待に応えられる様な課題解決能力を身につけ、
                        <br>新たな価値を創造してお客様に提供して参ります。 
                        </p>
                        <div class="ot-button align-self-center">
                            <a href="/recruit" class="octf-btn octf-btn-primary octf-btn-icon home_img_right">
                                <span>&emsp;採用情報&emsp;<i class="flaticon-right-arrow-1"></i></span>
                            </a>
                            <a href="/strength" class="octf-btn octf-btn-icon octf-btn-secondary">
                                &emsp;MCTの強み&emsp;<i class="flaticon-right-arrow-1"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> 

        <div class="signle_slider" id="signle_slider_3">
            <div class="banner-one__image">
                <img src="/assets/images/bannerAsset3.png" class="banner-one__image-1 " alt="">
            </div>
            <div class="row" style="height:580px;">
                <div class="col-lg-6" style="padding-top:102px">
                    <div class="banner-two__content" data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">
                        <h3 data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500"><span>先進のICT技術で</span></h3>
                        <p  data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">常に価値あるサービスを提供し、お客様と共に新しい未来を創ります。お客様へのサービス精神を持ち、日本のビジネス習慣を身に付け、豊富な技術ノウハウ・業務ノウハウを有した、真のSE人材を育成して参ります。
                        <br>Create your precious future.
                        </p>
                        <div class="ot-button align-self-center" data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">
                            <a href="/business" class="octf-btn octf-btn-primary octf-btn-icon octf-btn-third home_img_right">
                                <span>&emsp;事業内容&emsp;<i class="flaticon-right-arrow-1"></i></span>
                            </a>
                            <a href="/history" class="octf-btn octf-btn-icon octf-btn-secondary">
                                &emsp;MCTの沿革&emsp;<i class="flaticon-right-arrow-1"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
    <!-- </div> -->
    <!-- /.container -->
</section>
<!-- /.banner-one -->

<section class="service-three">
    <div class="container">
        <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
            <p><span>Business Contents</span></p>
            <h3>主な事業内容</h3>
        </div>
        <!-- /.block-title text-center -->
        <div class="row high-gutters">
            <!-- /.col-lg-4 -->
            <div class="col-lg-4">
                <div class="service-three__single wow flipInY" data-wow-delay="300ms" data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">
                    <div class="service-three__icon">
                        <div class="service-three__single-circle"></div>
                        <!-- /.service-three__single-circle -->
                        <img src="/assets/images/service-2.png" style="height:145px" alt="">
                    </div>
                    <!-- /.service-three__icon -->
                    <h3>開発・保守</h3>
                    <p>お客様のご要望に応じた「OS、DB、言語、ツール、フレームワーク」によるソフトウエア開発・保守サービス。 汎用コンピュータの開発、コンバージョン。</p>
                    <!-- /.thm-btn -->
                </div>
                <!-- /.service-three__single -->
            </div>
            <div class="col-lg-4 s2">
                <div class="service-three__single wow flipInY" data-wow-delay="0ms" data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">
                    <div class="service-three__icon">
                        <div class="service-three__single-circle"></div>
                        <!-- /.service-three__single-circle -->
                        <img src="/assets/images/service-1.png" style="height:145px" alt="">
                    </div>
                    <!-- /.service-three__icon -->
                    <h3>オフショア開発</h3>
                    <p>お客様のご要望を基づくオフショア開発。お客様のご要望によるラボ契約での運用監視サービス。 中国進出の日本企業向けのシステム開発・保守サービス。</p>
                    <!-- /.thm-btn -->
                </div>
                <!-- /.service-three__single -->
            </div>
            <!-- /.col-lg-4 -->
            <div class="col-lg-4">
                <div class="service-three__single wow flipInY" data-wow-delay="600ms" data-wow-duration="1500ms">
                    <div class="service-three__icon" data-aos="fade-right" :data-aos-duration="500">
                        <div class="service-three__single-circle"></div>
                        <!-- /.service-three__single-circle -->
                        <img src="/assets/images/service-3.png" style="height:130px" alt="">
                    </div>
                    <!-- /.service-three__icon -->
                    <h3 data-aos="fade-right" :data-aos-duration="500">BPO</h3>
                    <p data-aos="fade-right" :data-aos-duration="500">
                        コンサルティングから設計、開発、ハードウェア・ソフトウェアの選定導入、運用保守にいたるまで、システムに関するすべてをサポートします。
                    </p>
                    <!-- /.thm-btn -->

                </div>
                <!-- /.service-three__single -->
            </div>
        </div>
        <!-- /.row high-gutters -->
    </div>
    <!-- /.container -->
</section>

<section class="about-two">
    <img src="/assets/images/shapes/about-2-bg-1-1.png" class="about-two__bg-shape-1" alt="">
    <div class="container">
        <img src="/assets/images/shapes/about-2-bg-1-2.png" class="about-two__bg-shape-2" alt="">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-two__images wow fadeInLeft" data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">
                    <img src="/assets/images/about-us.png" class="float-bob-y" alt="">
                </div>
                <!-- /.about-two__images -->
            </div>
            <!-- /.col-lg-6 -->
            <div class="col-lg-6">
                <div class="about-two__content">
                    <div class="block-title text-left" data-aos="fade-right" :data-aos-duration="500">
                        <p><span>About Company</span></p>
                        <h3>{{HomeInfo.CompanyName}}について</h3>
                    </div>
                    <!-- /.block-title text-left -->
                    <p data-aos="fade-right" :data-aos-duration="500">弊社は「人材・信頼・共生・効率・創造」を礎に、未来に向けて優れたソリューションを持ち、 コンサルティングから運用・アウトソーシングまで、一流のサービスを提供する国際的情報サービスグループ企業を目指して参ります。
                            <br/>
                            当社のSES(システムエンジニアリングサービス)事業は、貴社の急な人手不足、イレギュラー対応が必要な短期的な作業にも、迅速にエンジニア/プログラマ等をご紹介致します。
                            豊富な経験と高度なスキルを持つビジネスエンジニアが、お客様のプロジェクトを成功へと導くことをお手伝い致します。
                            また、SES事業におけるパートナー企業様も積極的に募集しておりますので、ご興味のある企業様は、お気軽にご連絡下さいませ。
                            <br />
                            MCTは、トータルソリューションのご提案から個別システム開発まで、幅広いニーズ・シーズにご対応が可能な少数精鋭のエンジニアチームが開発案件をお待ちしております。
                            業務システムやWebサービス等の各種ソフトウェア開発について、要件定義、設計、プログラミング、テスト、運用の各工程に合わせたご要望を踏まえてハイスキルエンジニアが対応します。
                    </p>

                    <div class="about-two__counter-wrap">
                        <div class="about-two__counter" data-aos="fade-right" :data-aos-duration="500">
                            <a href="/about" class="octf-btn octf-btn-icon octf-btn-secondary">&emsp;MCT概要&emsp;<i class="flaticon-right-arrow-1"></i>
                            </a>
                        </div>
                        
                    </div>
                    <!-- /.about-two__counter-wrap -->
                </div>
                <!-- /.about-two__content -->
            </div>
            <!-- /.col-lg-6 -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container -->
</section>
<!-- /.about-two -->

<section class="cta-one cta-one__home-one">
    <div class="particles-snow" id="cta-one-snow"></div>
    <!-- /#cta-one-snow.particles-snow -->

    <img src="/assets/images/shapes/footer-shape-1-1.png" class="cta-one__bg-shape-1" alt="" data-aos="fade-right" :data-aos-duration="500">
    <img src="/assets/images/shapes/footer-shape-1-2.png" class="cta-one__bg-shape-2" alt="" data-aos="fade-right" :data-aos-duration="500">
    <img src="/assets/images/shapes/footer-shape-1-3.png" class="cta-one__bg-shape-3" alt="" data-aos="fade-right" :data-aos-duration="500">
    <img src="/assets/images/shapes/footer-shape-1-4.png" class="cta-one__bg-shape-4" alt="" data-aos="fade-right" :data-aos-duration="500">

    <div class="container" data-aos="fade-right" :data-aos-duration="500">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-two__images wow fadeInLeft" data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">
                    <img src="/assets/images/about-us-2.png" class="float-bob-y" alt="">
                </div>
                <!-- /.about-two__images -->
            </div>
            <!-- /.col-lg-6 -->
            <div class="col-lg-6">
                    <br/>   <br/>
                    <h3>社員と会社が共にある。<br>活躍できる舞台がこにある。</h3>
                    <!-- /.block-title text-left -->
                    <p data-aos="fade-right" :data-aos-duration="500" style="color:#fff">
                        システムエンジニアを目指す方を求めています。全学部・全学科からの募集で、社員の半数以上が文系出身、プログラミング未経験者です。ITのスペシャリストへ成長できる環境をご用意して、お待ちしています。
                    </p><br/>   <br/>
                    <!-- <router-link to="/recruit" class="thm-btn cta-one__btn"><span>Join Us Now</span></router-link> -->
                    <router-link to="/recruit" class="octf-btn octf-btn-icon octf-btn-secondary">&emsp;Join Us Now&emsp;<i class="flaticon-right-arrow-1"></i></router-link>
                    <!-- /.about-two__counter-wrap -->
                <!-- /.about-two__content -->
            </div>
            <!-- /.col-lg-6 -->
        </div>
        <!-- /.row -->
        <!-- /.thm-btn cta-one__btn -->
    </div>
    <!-- /.container -->
</section>
<!-- /.cta-one --> 

<section class="service-one">
    <div class="container">
        <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
            <p><span>Our Services</span></p>
            <h3>サービス</h3>
        </div>
        <!-- /.block-title text-center -->
        <div class="row high-gutters" id="service-one-gutters">

            <!-- /.col-lg-6 col-md-12 -->
            <div class="col-lg-6 col-md-12 wow fadeInLeft" data-wow-duration="1500ms">
                <div class="service-one__single">
                    <div class="service-one__icon" data-aos="fade-right" :data-aos-duration="500">
                        <div class="service-one__icon-inner">
                            <img src="/assets/images/shapes/service-i-3.png" alt="">
                        </div>
                        <!-- /.service-one__icon-inner -->
                    </div>
                    <!-- /.service-one__icon -->
                    <div class="service-one__content" data-aos="fade-right" :data-aos-duration="500">
                        <h3><router-link to="/serviceDetail/Smartphone">スマホ・タブレット</router-link></h3>
                        <p>スマートデバイス活用 アプリ・システム開発
                        </p>
                    </div>
                    <!-- /.service-one__content -->
                </div>
                <!-- /.service-one__single -->
            </div>
            <div class="col-lg-6 col-md-12 wow fadeInLeft" data-wow-duration="1500ms">
                <div class="service-one__single">
                    <div class="service-one__icon" data-aos="fade-right" :data-aos-duration="500">
                        <div class="service-one__icon-inner" id="service-one__icon-inner1">
                            <img src="/assets/images/shapes/service-i-1.png" alt="">
                        </div>
                        <!-- /.service-one__icon-inner -->
                    </div>
                    <!-- /.service-one__icon -->
                    <div class="service-one__content" data-aos="fade-right" :data-aos-duration="500">
                        <h3><router-link to="/serviceDetail/Development">システム開発</router-link></h3>
                        <p>金融・医療・物流</p>
                    </div>
                    <!-- /.service-one__content -->
                </div>
                <!-- /.service-one__single -->
            </div>
            <!-- /.col-lg-6 col-md-12 -->
            <div class="col-lg-6 col-md-12 wow fadeInRight" data-wow-duration="1500ms">
                <div class="service-one__single">
                    <div class="service-one__icon" data-aos="fade-right" :data-aos-duration="500">
                        <div class="service-one__icon-inner" id="service-one__icon-inner2">
                            <img src="/assets/images/shapes/service-i-2.png" alt="">
                        </div>
                        <!-- /.service-one__icon-inner -->
                    </div>
                    <!-- /.service-one__icon -->
                    <div class="service-one__content" data-aos="fade-right" :data-aos-duration="500">
                        <h3><router-link to="/serviceDetail/CRM">CRMシステム</router-link></h3>
                        <p>開発製造、既製品のカスタマイズ</p>
                    </div>
                    <!-- /.service-one__content -->
                </div>
                <!-- /.service-one__single -->
            </div>
            <!-- /.col-lg-6 col-md-12 -->
            <div class="col-lg-6 col-md-12 wow fadeInRight" data-wow-duration="1500ms">
                <div class="service-one__single">
                    <div class="service-one__icon" data-aos="fade-right" :data-aos-duration="500">
                        <div class="service-one__icon-inner">
                            <img src="/assets/images/shapes/service-i-4.png" alt="">
                        </div>
                        <!-- /.service-one__icon-inner -->
                    </div>
                    <!-- /.service-one__icon -->
                    <div class="service-one__content" data-aos="fade-right" :data-aos-duration="500">
                        <h3><router-link to="/serviceDetail/AI">人工知能</router-link></h3>
                        <p>AIテクノロジーへの取組</p>
                    </div>
                    <!-- /.service-one__content -->
                </div>
                <!-- /.service-one__single -->
            </div>
            <!-- /.col-lg-6 col-md-12 -->

             <div class="col-lg-6 col-md-12 wow fadeInLeft" data-wow-duration="1500ms">
                <div class="service-one__single">
                    <div class="service-one__icon" data-aos="fade-right" :data-aos-duration="500">
                        <div class="service-one__icon-inner" id="service-one__icon-inner1">
                            <img src="/assets/images/shapes/service-i-5.png" alt="">
                        </div>
                        <!-- /.service-one__icon-inner -->
                    </div>
                    <!-- /.service-one__icon -->
                    <div class="service-one__content" data-aos="fade-right" :data-aos-duration="500">
                        <h3><router-link to="/">ブロックチェーン</router-link></h3>
                        <p>偽造防止・暗号化技術・仕組み</p>
                    </div>
                    <!-- /.service-one__content -->
                </div>
                <!-- /.service-one__single -->
            </div>

              <!-- /.col-lg-6 col-md-12 -->
            <div class="col-lg-6 col-md-12 wow fadeInRight" data-wow-duration="1500ms">
                <div class="service-one__single">
                    <div class="service-one__icon" data-aos="fade-right" :data-aos-duration="500">
                        <div class="service-one__icon-inner" id="service-one__icon-inner2">
                            <img src="/assets/images/shapes/service-i-6.png" alt="">
                        </div>
                        <!-- /.service-one__icon-inner -->
                    </div>
                    <!-- /.service-one__icon -->
                    <div class="service-one__content" data-aos="fade-right" :data-aos-duration="500">
                        <h3><router-link to="/">ビザ・海外医療</router-link></h3>
                        <p>ビザの手配・申込、医療の「サポート、案内、翻訳」</p>
                    </div>
                    <!-- /.service-one__content -->
                </div>
                <!-- /.service-one__single -->
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container -->
</section>
<!-- /.service-one -->

<section class="about-three">
    <img src="/assets/images/shapes/about-3-bg-1-1.png" class="about-three__bg-image-1" alt="">
    <div class="container">
        <img src="/assets/images/shapes/about-3-bg-1-2.png" class="about-three__bg-image-2" alt="">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-three__content">
                    <div class="block-title text-left" data-aos="fade-right" :data-aos-duration="500">
                        <p class="color-2"><span>MCT Advantage</span></p>
                        <h3>MCTシステムの強み</h3>
                    </div>
                    <!-- /.block-title text-center -->
                    <p data-aos="fade-right" :data-aos-duration="500">
                        先端技術への対応力<br/>
                        常に最新技術、最新ソリューションの調査・研究開発を推進しています。IOT、AI、クラウド、モバイルも早い段階から取り込み、豊富なノウハウを身に付け、常に世界における先端技術と先端開発手法に挑戦・習得し、常にお客様の期待に応えていきます。<br/>
                        最適・高品質なｼｽﾃﾑ構築の実現<br/>
                        長年の数多くのシステム開発を通して、多種多様な業種・業務への対応、および上流工程対応力・マネジメント力、上流工程から保守・運用に至る迄、高品質・低コストのサービスを提供させて頂いております。今後も、お客様へ更なる付加価値を提供できるように、理解力と提案力を磨き続け、お客様の期待に応えていきます。
                        <br>  <br>
                        <a href="/strength" class="octf-btn octf-btn-icon octf-btn-secondary">&emsp;詳しく&emsp;<i class="flaticon-right-arrow-1"></i></a>
                    </p>
                    
                    <!-- /.about-three__progress-wrap -->
                </div>
                <!-- /.about-three__content -->
            </div>
            <!-- /.col-lg-6 -->
            <div class="col-lg-6" data-aos="fade-right" :data-aos-duration="500">
                <div class="about-three__images wow fadeInRight" data-wow-duration="1500ms">
                    <img src="/assets/images/about-3-moc.png" class="float-bob-x about-three__image-3" alt="">
                </div>
                <!-- /.about-three__images -->
            </div>
            <!-- /.col-lg-6 -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container -->
</section>
<!-- /.about-three -->

<section class="pricing-one">
    <div class="container">
        <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
            <p class="color-2"><span>News</span></p>
            <h3>お知らせ</h3>
        </div>
        <!-- /.block-title text-center -->
        <div class="row high-gutters">
            <div class="item col-lg-4" style="margin-bottom:30px;min-height:370px" v-for="(item, index) in newsList" :key="index">
                <div class="blog-one__single">
                    <div class="blog-one__image" style="height: 200px;overflow: hidden;">
                            <img :src="item.img" style="height: 200px;">
                            <router-link :to="`/news/${item.id}`"><i class="fal fa-plus"></i></router-link>
                    </div>
                    <!-- /.blog-one__image -->
                    <div class="blog-one__content">
                        <div class="blog-one__meta">
                            <router-link :to="`/news/${item.id}`">
                                {{ item.time }}
                            </router-link>
                        </div>
                        <!-- /.blog-one__meta -->
                        <h3 style="font-size:16px;word-break:break-all;word-wrap:break-word;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"> <router-link :to="`/news/${item.id}`">{{ item.title }}</router-link></h3>
                        <router-link :to="`/news/${item.id}`" class="thm-btn blog-one__btn"><span>Read More</span></router-link>
                        <!-- /.thm-btn blog-one__btn -->
                    </div>
                    <!-- /.blog-one__content -->
                </div>
                <!-- /.blog-one__single -->
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container -->
</section>
<!-- /.pricing-one -->
</template>

<style scoped>
.video-wrapper {
  height: 100vh;
  position: relative;
}

.background { 
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%; 
  opacity: 0.6;
  object-fit: cover; 
}
</style>
<script>
// banner設定
function slickDots (){
    if($('.slick-dots').length) {
        let slickList = $('.slick-dots')[0].childNodes;
        if (slickList.length) {
            var point = 1;
            $('#bannercroll').css('background-image','')
            for (var i=1; i <= slickList.length; i++) {
                point = i;
                const className = slickList[i-1].className;
                if(i==1 && className) { 
                    $('#bannercroll').css('background-image','')
                    $('#bannercroll').css('background-image','/images/first_banner.jpg');
                }
                else if(i==2 && className) {
                    $('#bannercroll').css('background-image','-webkit-radial-gradient(50% 50%, circle closest-side, rgba(11, 60, 175, 0.8) 0%, #0c44b3 100%)');
                    // -webkit-radial-gradient(50% 50%, circle closest-side, rgba(11, 60, 175, 0.8) 0%, #0c44b3 100%);
                } else if(i==3 && className) {
                    $('#bannercroll').css('background-image','-webkit-radial-gradient(50% 50%, circle closest-side, rgba(47, 18, 137, 0.7) 0%, #4f1eab 100%)');
                } else {
                    //   $('#bannercroll').css('background-image','/images/first_banner.jpg');
                }
            }
        }
    }
}
window.setInterval(slickDots, 100);
import { onMounted, ref,onUnmounted } from "vue";
export default {
  name: "Home",
  setup() {
     const initSlick = () => {
      $(".hero_slider").slick({
        arrows: false,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        fade: true,
      });
      $(".project_button button").on("click", function () {
        $(".project_button button").removeClass("active_btn");
        var filterValues = $(this).data("filter");
        $(".projects_slider_content").slick("slickUnfilter");
        $(".projects_slider_content").slick("slickFilter", filterValues);
        $(this).addClass("active_btn");
      });
      $(".projects_slider_content").slick({
        arrows: false,
        dots: true,
        infinite: true,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 780,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
      $(".testimonial_slide").slick({
        arrows: false,
        dots: true,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        fade: true,
        slidesToScroll: 1,
      });
    };
    const newsList = ref([]);
    const getNewsList = () => {
      $.ajax({
        type: "GET",
        url: "/api/news.php",
        dataType: "json",
        data: {
          page: 1,
        },
        success: (res) => {
          newsList.value = res.list.slice(0, 3);
        },
      });
    };
    onMounted(() => {
      initSlick();
      getNewsList();
      resize();
    });
    function resize() {
      const height = $(window).height();
      $('#bannercroll').css('height',height);
    }
    window.addEventListener('resize', resize)
    onUnmounted(() => {
      window.removeEventListener('resize', resize)
    })
    return { newsList };
  },
  computed: {
    isMobile : function() {
       if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
    }
  }
};
</script>
