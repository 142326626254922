<template>
  <!-- Start MCT breadcrumb section -->
  <BreadCrumb ImageUrl="/assets/images/shapes/header-bg-7.png" PageName="アクセス"/>
  <!-- End MCT breadcrumb section -->
  <!-- <img src="/assets/images/shapes/bg-shape-1-2.png" class="error-404__bg-shape-2" alt="">
  <img src="/assets/images/shapes/bg-shape-1-3.png" class="error-404__bg-shape-3" alt=""> -->

  <div class="error-404__bubble-1"></div>
  <!-- /.error-404__bubble-1 -->
  <div class="error-404__bubble-2"></div>
  <!-- /.error-404__bubble-2 -->
  <div class="error-404__bubble-3"></div>
  <!-- /.error-404__bubble-3 -->
  <div class="error-404__bubble-4"></div>
  <!-- /.error-404__bubble-4 -->
  <div class="error-404__bubble-5"></div>
  <!-- /.error-404__bubble-5 -->
  <div class="error-404__bubble-6"></div>
  <!-- /.error-404__bubble-6 -->
  <div class="error-404__bubble-7"></div>
  <!-- /.error-404__bubble-7 -->
  <div class="error-404__bubble-8"></div>
  <section class="faq-one__form-wrap faq-one__home-three faq-one" style="margin-top:30px">
  　　<div class="container">
      <div class="row">
          <div class="col-lg-6 flex_pc">
              <div class="faq-one__form-image" data-aos="fade-right" :data-aos-duration="500">
                  <img src="/assets/images/slide1.png" alt="">
                  <img src="/assets/images/mocups/banner-moc-3-1.png" alt="" style="display:none">
              </div>
              <!-- /.faq-one__form-image -->
          </div>
          <!-- /.col-lg-6 -->
          <div class="col-lg-6 d-flex">
              <div class="my-auto">
                  <div class="block-title text-left text-phone-center" data-aos="fade-right" :data-aos-duration="500">
                      <p><span>Access</span></p>
                  </div>
                  <!-- /.block-title text-center -->
                  <div class="accrodion-grp" data-grp-name="career-one__accrodion">
                      <div class="accrodion " data-aos="fade-right" :data-aos-duration="500">
                          <div class="accrodion-title">
                              <h4 id="address_1">所在地</h4>
                          </div>
                          <div class="accrodion-content">
                              <div class="inner">
                                  <p>
                                    〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}
                                  </p>
                              </div>
                              <!-- /.inner -->
                          </div>
                      </div>
                      <div class="accrodion" data-aos="fade-right" :data-aos-duration="500">
                          <div class="accrodion-title">
                              <h4 id="car_1">アクセス方法</h4>
                          </div>
                          <div class="accrodion-content">
                              <div class="inner">
                                  <p>
                                    JR「新小岩」南口より徒歩10分 <br>
                                  </p>
                              </div>
                              <!-- /.inner -->
                          </div>
                      </div>
                      <div class="accrodion" data-aos="fade-right" :data-aos-duration="500">
                          <div class="accrodion-title">
                              <h4 id="tel_1">代表電話</h4>
                          </div>
                          <div class="accrodion-content">
                              <div class="inner">
                                  <p>
                                    <a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a>
                                  </p>
                              </div>
                              <!-- /.inner -->
                          </div>
                      </div>
                  </div>
              </div>
              <!-- /.my-auto -->
          </div>
          <!-- /.col-lg-6 -->
      </div>
      <!-- /.row -->
    </div>
  <!-- /.container -->
  </section>
  <!-- /.faq-one__form-wrap -->
  <section class="contact-map__block">
    <div class="container">
        <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
            <p><span> Map </span></p>
            <h3>MCTの所在地</h3>
        </div>
        <!-- <iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
        <iframe :src="HomeInfo.MapUrl"
            class="google-map__contact-page" allowfullscreen data-aos="fade-right" :data-aos-duration="500"></iframe>
    </div>
    <!-- /.container -->
  </section>
  <!-- /.contact-map__block -->
</template>

<script>
import BreadCrumb from "../components/BreadCrumb";

export default {
  name: "Access",
  components: { BreadCrumb},
  methods: {
    isMobile : function() {
       if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
    }
  }
}
</script>
