<template>
  <!-- Start MCT breadcrumb section -->
  <BreadCrumb ImageUrl="/assets/images/shapes/header-bg-3.png" PageName="会社概要"/>
  <!-- End MCT breadcrumb section -->
  <section class="service-one">
    <div class="container" style="
    background-image: url(/assets/images/about-1.png);
    background-repeat: no-repeat;
    background-position: right -21px center;
    background-size: 70%;
">

        <div class="block-title text-center">
            <p><span>About MCT</span></p>
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-list-alt"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>会社名</h4>
                <p>{{HomeInfo.CompanyName}}{{HomeInfo.Abbreviation}}</p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
         <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-laptop"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>会社名(英語表記)</h4>
                <p>{{HomeInfo.CompanyNo}}</p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-building"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>住所</h4>
                <p>
                  〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-phone" style="background-image: linear-gradient(-45deg, rgb(64, 158, 255) 0%, rgb(255 64 197) 100%);"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>代表電話番号</h4>
                <p>
                  <span v-if="HomeInfo.Fax" >TEL. </span><a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a>
                  <span v-if="HomeInfo.Fax" >&emsp;FAX. {{HomeInfo.Fax}}</span>
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-envelope-open" style="background-image: linear-gradient(-45deg, #409eff 0%, rgb(235, 255, 64) 100%)"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>E-mail</h4>
                <p>
                  <a :href="'mailto:'+HomeInfo.Email">{{HomeInfo.Email}}</a>
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>

        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-user-circle"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>代表者名 (CEO)</h4>
                <p>
                  {{HomeInfo.Representative}}<span v-if="HomeInfo.Katakana">（{{HomeInfo.Katakana}}）</span>
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single" v-if="HomeInfo.CTO!=''">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-users"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>取締役</h4>
                <p>
                  {{HomeInfo.CTO}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-jpy"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>資本金</h4>
                <p>
                  {{formatPrice(this.HomeInfo.Capital)}}万円
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-calendar-check-o"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>設立</h4>
                <p>
                  {{HomeInfo.CreateDate}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single"  v-if="HomeInfo.Employees!='' && HomeInfo.Employees > 0">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-address-card"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>従業員(人数)</h4>
                <p>
                  {{HomeInfo.Employees}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single"  v-if="HomeInfo.Banks!=''">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-university"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>主要取引銀行</h4>
                <p>
                  {{HomeInfo.Banks}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single" v-if="HomeInfo.YearSell!=''">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-line-chart"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>売上</h4>
                <p>
                  {{HomeInfo.YearSell}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single" v-if="HomeInfo.HakenNo!=''">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-gift"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>労働者派遣事業許可番号</h4>
                <p>
                  {{HomeInfo.HakenNo}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>

    </div>
    <!-- /.container -->
</section>
</template>

<script>
import {ref} from "vue";
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "About",
  components:{ BreadCrumb },
  setup() {
      const HomeInfo = ref({})
      $.ajax({
        type: "GET",
        url: "/api/company.php",
        dataType: "json",
        // crossDomain: true,
        beforeSend: function(xhr){
             xhr.withCredentials = true;
             xhr.setRequestHeader('Access-Control-Allow-Origin','*');
             xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true');
        },
        async: false,
        success: (res) => {
            HomeInfo.value = res;
        },
        error: (res) => {
           HomeInfo.value =   {
                Tel: '03-5539-9492',
                Fax: '03-5539-9492',
                PostCode: '124-0024',
                Email: 'mct@mcts.co.jp',
                Address: '東京都葛飾区新小岩3丁目27-21',
                Building: '',
                CompanyName: 'MCTシステム株式会社',
                CompanyNo: 'Medium Chain Technology System Co., Ltd.',
                Abbreviation: '（略称：MCT）',
                Representative: '浅見　国文',
                Katakana: 'アサミ　コクブン',
                CTO:'西村　光男（ニシムラ　ミツオ）',
                Capital: '900',
                Banks: '三菱UFJ銀行',
                CreateDate: '2023年02月01日',
                YearSell: '',
                HakenNo: '', // '派13-○○○○○○'
                Employees:'',
                //<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1406.6569637960001!2d139.62157875227183!3d35.906611639238555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018c1d6eabbc35b%3A0x2e923ce55d1f0076!2z5qCq5byP5Lya56S-44K444Kn44O844K344O844Ko44Or!5e0!3m2!1sja!2sjp!4v1656845420181!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                MapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1406.6569637960001!2d139.62157875227183!3d35.906611639238555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018c1d6eabbc35b%3A0x2e923ce55d1f0076!2z5qCq5byP5Lya56S-44K444Kn44O844K344O844Ko44Or!5e0!3m2!1sja!2sjp!4v1656845420181!5m2!1sja!2sjp'
           }
        }
     })
     return {HomeInfo}
  },
  methods: {
    formatPrice(value) {
        return (value||'1000').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>
