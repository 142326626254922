import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementUI from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({
    offset: 20,
    delay: 300, // values from 0 to 3000, with step 50ms
    duration: 800 // values from 0 to 3000, with step 50ms
});
let HomeInfo = {
    Tel: '03-5539-9492',
    Fax: '03-5539-9492',
    PostCode: '124-0024',
    Email: 'mct@mcts.co.jp',
    Address: '東京都葛飾区新小岩3丁目27-21',
    Building: '',
    CompanyName: 'MCTシステム株式会社',
    CompanyNo: 'Medium Chain Technology System Co., Ltd.',
    Abbreviation: '（略称：MCT）',
    Representative: '浅見　国文',
    Katakana: 'アサミ　コクブン',
    Capital: '900',
    Banks: '三菱UFJ銀行',
    CreateDate: '2023年02月01日',
    YearSell: '',
    HakenNo: '', // '派13-○○○○○○'
    CTO: '西村　光男（ニシムラ　ミツオ）',
    //<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1406.6569637960001!2d139.62157875227183!3d35.906611639238555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018c1d6eabbc35b%3A0x2e923ce55d1f0076!2z5qCq5byP5Lya56S-44K444Kn44O844K344O844Ko44Or!5e0!3m2!1sja!2sjp!4v1656845420181!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    MapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.5082566855294!2d139.8660558815376!3d35.71371678251065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188611253ed7b5%3A0xe059d235622b113d!2z44CSMTI0LTAwMjQg5p2x5Lqs6YO96JGb6aO-5Yy65paw5bCP5bKp77yT5LiB55uu77yS77yX4oiS77yS77yR!5e0!3m2!1sja!2sjp!4v1675322861866!5m2!1sja!2sjp'
}
const app = createApp(App)
    // load 
$.ajax({
    type: "GET",
    url: "/api/company.php",
    dataType: "json",
    success: (res) => {
        HomeInfo = res
        app.config.globalProperties.HomeInfo = res
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
    error: (res) => {
        app.config.globalProperties.HomeInfo = HomeInfo
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
});